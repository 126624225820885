import React, { useState } from 'react';
import axios from 'axios';
import './App.css'; // Import the CSS file

function App() {
  const [text, setText] = useState('');
  const [data, setData] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form submitted with text:', text); // Debugging line

    // Create a FormData object and append the deck data
    const formData = new FormData();
    formData.append('deck', text);

    try {
      const response = await axios.post('https://api.vps.r3quie.com', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Response:', response.data);
      setData(response.data); // Update state with received data
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  const colorMapping = {
    U: { name: 'Blue', className: 'progress-blue', img: 'u.svg' },
    R: { name: 'Red', className: 'progress-red', img: 'r.svg' },
    B: { name: 'Black', className: 'progress-black', img: 'b.svg' },
    G: { name: 'Green', className: 'progress-green', img: 'g.svg' },
    W: { name: 'White', className: 'progress-white', img: 'w.svg' },
    C: { name: 'Colorless', className: 'progress-colorless', img: 'c.svg' },
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Text Input Form</h1>
      <form onSubmit={handleSubmit}>
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          rows="20"
          cols="50"
          placeholder="Enter up to 100 lines of text here..."
          style={{ width: '100%', height: '300px' }}
        />
        <br />
        <button type="submit">Submit</button>
      </form>

      {data && (
        <div style={{ marginTop: '20px' }}>
          <h2>Received Data</h2>
          {Object.keys(colorMapping).map((color) => (
            <div key={color} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
              <img src={colorMapping[color].img} alt={colorMapping[color].name} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
              <label>{colorMapping[color].name}: {data[color].toFixed(2)}</label>
              <progress
                value={data[color]}
                max={7 - data.Non}
                className={colorMapping[color].className}
                style={{ width: '100%', marginLeft: '10px' }}
              ></progress>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default App;